@import "../styles/imports.scss";

.brochure-wrapper {
  background-image: url("../images/brouchure-banner.jpg");
  background-position: center;
  // background-repeat: no-repeat;
  background-size: cover;
  // background: linear-gradient(131.68deg, #005f8f 0%, #008f7c 100%);
  padding: 15px;
  height: 650px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .left-block {
    padding: 70px;
    h1 {
      font-size: 50px;
      color: white;
      margin-bottom: 100px;
      line-height: 84px;
    }

    .brochure-button {
      border-radius: 30px;
      background-color: #ffffff;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
      text-decoration: none;
      color: #005f8f;
      //   display: grid;
      //   grid-template-columns: 1fr 4fr;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      height: 60px;
      width: 300px;
      padding-left: 30px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 27px;

      img {
        padding-right: 30px;
      }
    }
  }
}
