@import "../styles/imports.scss";

.about-us-wrapper {
  background-image: url("../images/about-us-pattern.png");
  background-color: #f7f7f7;
  height: 700px;
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;

  .about-us-content {
    width: 70%;
    h3 {
      color: #005f8f;
      font-size: 48px;
      //font-weight: 500;
      letter-spacing: 0;
      line-height: 72px;
      margin-bottom: 30px;
    }

    span {
      color: #005f8f;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 50px;
    }
  }

  .map {
    position: absolute;
    right: 150px;
    top: 10px;
    height: 700px;
  }
}
