@import "./styles/imports.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
ul,
li,
form {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: $font-family-medium;
}
