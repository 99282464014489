@import "../styles/imports.scss";

.contact-us-wrapper {
  background: linear-gradient(314.74deg, #005f8f 0%, #008f7c 100%);
  padding: 85px;
  h3 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 72px;
    text-align: center;
    margin-bottom: 50px;
  }
  .contact-us-block {
    display: grid;
    grid-template-columns: 0.5fr 1fr;

    h4 {
      color: #ffffff;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 52px;
      margin-bottom: 30px;
    }

    ul {
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      list-style: none;

      li {
        margin-bottom: 50px;
      }
    }

    .contact-form-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .contact-form {
      width: 70%;

      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 50px 100px;

      .success-block {
        background-color: white;
        border-radius: 5px;
        font-family: $font-family-bold;
        font-size: 14px;
        padding: 10px;
        text-align: center;
        margin-bottom: 30px;
        color: #008f7c;
      }

      .border {
        background: linear-gradient(314.74deg, #005f8f 0%, #008f7c 100%);
        height: 3px;
        margin-bottom: 50px;
      }

      .right-align {
        display: flex;
        justify-content: flex-end;
      }

      .whatsapp-button {
        //display: block;
        border-radius: 8px;
        background-color: #1bd741;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
        width: 100%;
        text-decoration: none;
        color: #ffffff;
        outline: none;
        border: none;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        margin: 0;
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .connect-button {
    width: 50% !important;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
