@import "../styles/imports.scss";

.footer-wrapper {
  padding: 0 85px;
  // height: 20vh;
  font-size: 14px;

  .logo {
    height: 65px;
  }

  .footer-top-block {
    padding: 30px 0;
    border-bottom: 1px solid #f1f1f1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .social-media-icons {
      display: flex;
      column-gap: 12px;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }

  .footer-bottom-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px 0;
    font-family: $font-family-light;

    color: #005f8f;

    .footer-bottom-block-icons {
      width: 15px;
      height: 21px;
      margin-right: 10px;
      align-items: center;
      align-content: center;
    }

    .address-block {
      display: flex;
      flex-direction: row;
    }
  }

  .copyrights-block {
    font-family: $font-family-light;
    color: #252525;
    letter-spacing: 0;
    line-height: 26px;
    padding-bottom: 20px;
  }
  .center-align {
    display: flex;
    justify-content: center;
  }
}
