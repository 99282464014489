@import "../styles/imports.scss";

.outsource-wrapper {
  background-image: url("../images/outsource-pattern.png");
  // background-color: rgba(0, 95, 143, 0.2);
  background: linear-gradient(45deg, #012a3f 0%, #023856 100%);
  //012A3F to 023856
  padding: 85px;

  h3 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 72px;
    margin-bottom: 100px;
  }

  .outsource-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;

    ul {
      list-style: none;

      li {
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 70px;
      }
    }

    .right-align {
      align-items: flex-end;
      justify-self: flex-end;
    }
  }
}
