@import "../styles/imports.scss";

.benefits-wrapper {
  background-color: #005f8f;
  padding: 85px;

  h3 {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 72px;
    color: #ffffff;
    margin-bottom: 50px;
  }

  .benefits-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;

    ul {
      list-style: none;

      li {
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }

    .text-align-right {
      text-align: right;
    }
  }
}
