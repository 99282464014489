@import "../styles/imports.scss";

.textbox-wrapper {
  position: relative;
  width: 100%;
  label {
    display: block;
    padding-bottom: 8px;
    color: #ffffff;
  }

  input[type="text"] {
    width: 100%;
    height: 40px;
    font-family: $font-family;
    font-size: 14px;
    line-height: 40px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 25px;

    &.error {
      border: 2px solid #e02020 !important;
      box-shadow: none !important;
    }

    // @include mobile-360 {
    //   margin-bottom: 15px;
    // }
    // @include mobile-320 {
    //   height: 35px;
    //   margin-bottom: 13px;
    //   font-size: 12px;
    // }
  }

  input[type="text"]:focus {
    outline: 0;
    background-color: #ffffff;
  }
}
