@import "../styles/imports.scss";

.button {
  height: 48px;
  width: 100%;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  outline: none;
  cursor: pointer;

  //   @include mobile-360 {
  //     font-size: 15px;
  //     height: 40px;
  //   }

  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #005f8f;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    font-weight: 500;
  }
}
